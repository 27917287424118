@charset "UTF-8";

// Globals
@import "src/Assets/Styles/utilities/includes";

/**
 * Header
 */

.header {
	background-color: $accent;
	
	.logotype {
		margin-bottom: 0;
		font-size: 2rem;
		color:#fff;
	}
	
}

/**
 * Navigation
 */

.nav {
	$self : &;
	display: flex;
	justify-content: flex-end;
	gap: 2rem;
	list-style: none;
	
	&__item {
		position: relative;
		font-size: 1.6rem;
		color: #fff;
		
		&__icon {
			vertical-align: middle;
			transform: translateY(-2px);
			transition: color 0.25s ease;
			@include icons {
				font-size: 125%;
				color: #fff;
			}
			
			&--menu:after {
				content: 'm';
			}
			
			&--user:after {
				content: 'u';
			}
			
			&--back:after {
				content: 'b';
			}
			
			&--help:after {
				content: 'h';
			}
			
			&--exit:after {
				content: 'e';
			}
			
		}
		
	}
	
	&__link {
		all: unset;
		cursor: pointer;
		transition: color 0.25s ease;
		text-decoration: none;
		color: #fff;
		
		// 768px and up
		@include media-query(md) {
			
			&:hover {
				
				&,
				&#{$self}--active,
				#{$self}__item__icon {
					color: lighten($accent, 40%);
				}
				
			}
			
		}
		
	}
	
	&__submenu {
		display: none;
		position: absolute;
		top: 150%;
		left: -1rem;
		padding: 1.5rem 2rem 1.5rem 1.5rem;
		background-color: #fff;
		border: 1px solid $grey-400;
		border-radius: 2rem;
		
		&--visible {
			display: block;
		}
		
		#{$self}__item {
			margin-bottom: 1.5rem;
			@include last-child;
			
			#{$self}__item__icon {
				margin-right: 0.5rem;
				vertical-align: bottom;
				font-size: 115%;
			}
			
		}
		
		#{$self}__link {
			font-size: 95%;
		}
		
		#{$self}__item #{$self}__link,
		#{$self}__item #{$self}__link #{$self}__item__icon {
			color: $grey-900;
		}
		
	}
	
}