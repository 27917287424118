@charset "UTF-8";

@font-face {
	font-family: 'CustomIcons';
	src: url('../../Fonts/Icons/icons.eot');
	src: url('../../Fonts/Icons/icons.eot?#iefix') format('embedded-opentype'),
	url('../../Fonts/Icons/icons.woff') format('woff'),
	url('../../Fonts/Icons/icons.ttf') format('truetype'),
	url('../../Fonts/Icons/icons.svg#icons') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}