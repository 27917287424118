@charset "UTF-8";

/**
 * Fontface mixin
 * @param string $weight Font family
 * @param int    $weight Font weight
 * @param type   $style  Font Style
 * @return rule
 */

@mixin font-face($family, $weight: 400, $style: normal, $fallback: sans-serif) {
	font-family: '#{$family}', $fallback;
	font-weight: $weight;
	font-style: $style;
	@content;
}

/**
 * Inter mixin
 * @param int  $weight Font weight
 * @param type $style  Font Style
 * @return rule
 */

@mixin inter($weight: $font-weight-light, $style: normal) {
	@include font-face('Inter', $weight, $style) {
		@content;
	};
}

/**
 * Icons mixin
 * @return rule
 */

@mixin icons() {
	@include font-face('CustomIcons', normal) {
		@content;
	};
}

/**
 * Input placeholder styles
 */

@mixin placeholder() {
	&::-webkit-input-placeholder,
	&::-moz-placeholder,
	&:-ms-input-placeholder,
	&:-moz-placeholder,
	&::placeholder {
		@content;
	}
}

/**
 * Last child margin reset
 * @param property $property
 */

@mixin last-child($property: margin-bottom, $value: 0) {
	&:last-child {
		#{$property}: $value;
		@content;
	}
}

/**
 * Vertical align using position absolute
 */

@mixin vertical-align($offset: 50%, $position: absolute) {
	position: $position;
	top: $offset;
	transform: translateY(-50%);
}

/**
 * Breakpoints
 * @param string $name
 */

@mixin media-query($name) {
	$min : map-get($breakpoints, $name);
	@at-root {
		@media (min-width: #{$min}) {
			& {
				@content;
			}
		}
	}
}

/**
 * Creates a set of options (extends the current selector)
 * @param list $combos List example $list : (".selector-added-class" : ( "property" value, "property" value ))
 */

@mixin create-options($combos) {
	@each $combo in $combos {
		$selector : unquote(nth($combo, 1));
		$options  : nth($combo, 2);
		$parent: #{if(&, '&', '')}; // @see https://stackoverflow.com/questions/75799570/sasserror-top-level-selectors-may-not-contain-the-parent-selector-webk
		#{$parent}#{$selector} {
			@each $option in $options {
				$property : unquote(nth($option, 1));
				@if(type-of($property) == 'string') {
					$property : unquote($property);
				}
				$value    : nth($option, 2);
				@if(type-of($value) == 'string') {
					$value : unquote($value);
				}
				#{$property} : #{$value};
			}
		}
	}
}