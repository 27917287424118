// Sizes
$max-width            : 1200px;

// Breakp0ints
$breakpoints          : (
	xs  : 0,
	sm  : 576px,
	md  : 768px,
	lg  : 992px,
	xl  : 1200px,
	xxl : 1400px
);

// Font weights
$font-weight-light    : 300;
$font-weight-regular  : 400;
$font-weight-medium   : 500;
$font-weight-semibold : 600;
$font-weight-bold     : 700;