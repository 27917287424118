@charset "UTF-8";

// Globals
@import "src/Assets/Styles/utilities/includes";

// Hard reset
@import "src/Assets/Styles/utilities/reset";

// Global utilities
@import "src/Assets/Styles/utilities/utilities";

// Webfonts
@import "Components/webfonts";

/**
 * Common
 */

*,
*::before,
*::after {
	box-sizing: border-box;
}

:root {
	font-size: 62.5%;
}

body {
	background-color: #fff;
	@include inter {
		font-size: 1.6rem;
		color: $grey-900;
	};
}

a,
.link {
	color: $accent;
	text-decoration: underline;
	
	// 992px and up
	@include media-query(lg) {
		transition: color 0.25s ease;
		
		&:hover {
			color: $accent-hover;
		}
		
	}
	
}

.link {
	
	&__small {
		font-size: 85%;
	}
	
}

// Extends
@import "src/Assets/Styles/utilities/extends";

.app {
	$self : &;
	user-select: none;
	
	&--full-height {
		display: flex;
		flex-direction: column;
		height: 100dvh;
		
		#{$self}__element {
			flex: 0 0 auto;
			
			&--grow {
				flex: 1 0 auto;
			}
			
			&--shrink {
				flex: 0 1 auto;
			}
			
		}
		
	}
	
}

/**
 * Rows
 */

.row {
	display: flex;
	flex-wrap: wrap;
	
	> * {
		flex: 0 0 auto;
		width: 100%;
	}
	
	/**
	 * Align content
	 */
	
	&--align-content-center {
		align-content: center;
	}
	
	/**
	 * Align items
	 */
	
	&--align-items-center {
		align-items: center;
	}
	
	// 768px and up
	@include media-query(md) {
		
		&--align-items-md-center {
			justify-content: center;
		}
		
	}
	
	/**
	 * Justify content
	 */
	
	// 768px and up
	@include media-query(md) {
		
		&--justify-content-md-center {
			align-content: center;
		}
		
	}
	
	/**
	 * Direction
	 */
	
	&--direction-column {
		flex-direction: column;
	}
	
}

/**
 * Columns
 */

.col {
	flex: 0 0 auto;
	width: 100%;
	
	/**
	 * Spacing
	 */
	
	&--grow {
		flex: 1 0 auto;
	}
	
	&--shrink {
		flex: 0 1 auto;
	}
	
	/**
	 * Columns sizes
	 */
	
	@include create-options((
		"--size-10" (
			"width" 10%,
		),
		"--size-20" (
			"width" 20%,
		),
		"--size-30" (
			"width" 30%,
		),
		"--size-40" (
			"width" 40%,
		),
		"--size-50" (
			"width" 50%,
		),
		"--size-60" (
			"width" 60%,
		),
		"--size-70" (
			"width" 70%,
		),
		"--size-80" (
			"width" 80%,
		),
		"--size-90" (
			"width" 90%,
		),
		"--size-100" (
			"width" 100%,
		),
	));
	
	// 768px and up
	@include media-query(md) {
		
		/**
		 * Columns sizes
		 */
		
		@include create-options((
			"--size-md-10" (
				"width" 10%,
			),
			"--size-md-20" (
				"width" 20%,
			),
			"--size-md-30" (
				"width" 30%,
			),
			"--size-md-40" (
				"width" 40%,
			),
			"--size-md-50" (
				"width" 50%,
			),
			"--size-md-60" (
				"width" 60%,
			),
			"--size-md-70" (
				"width" 70%,
			),
			"--size-md-80" (
				"width" 80%,
			),
			"--size-md-90" (
				"width" 90%,
			),
			"--size-md-100" (
				"width" 100%,
			),
		));
		
		/**
		 * Orders
		 */
		
		&--order-md-1 {
			order: 1;
		}
		
		&--order-md-2 {
			order: 2;
		}
		
	}
	
	// 991px and up
	@include media-query(lg) {
		
		/**
		 * Columns sizes
		 */
		
		@include create-options((
			"--size-lg-10" (
				"width" 10%,
			),
			"--size-lg-20" (
				"width" 20%,
			),
			"--size-lg-30" (
				"width" 30%,
			),
			"--size-lg-40" (
				"width" 40%,
			),
			"--size-lg-50" (
				"width" 50%,
			),
			"--size-lg-60" (
				"width" 60%,
			),
			"--size-lg-70" (
				"width" 70%,
			),
			"--size-lg-80" (
				"width" 80%,
			),
			"--size-lg-90" (
				"width" 90%,
			),
			"--size-lg-100" (
				"width" 100%,
			),
		));
		
	}
	
	// 1199px and up
	@include media-query(xl) {
		
		/**
		 * Columns sizes
		 */
		
		@include create-options((
			"--size-xl-10" (
				"width" 10%,
			),
			"--size-xl-20" (
				"width" 20%,
			),
			"--size-xl-30" (
				"width" 30%,
			),
			"--size-xl-40" (
				"width" 40%,
			),
			"--size-xl-50" (
				"width" 50%,
			),
			"--size-xl-60" (
				"width" 60%,
			),
			"--size-xl-70" (
				"width" 70%,
			),
			"--size-xl-80" (
				"width" 80%,
			),
			"--size-xl-90" (
				"width" 90%,
			),
			"--size-xl-100" (
				"width" 100%,
			),
		));
		
	}
	
}

/**
 * Heights
 */

.h {
	
	@include create-options((
		"-25" (
			"height" 25%,
		),
		"-50" (
			"height" 50%,
		),
		"-75" (
			"height" 75%,
		),
		"-100" (
			"height" 100%,
		),
	));
	
	// 768px and up
	@include media-query(md) {
		
		@include create-options((
			"-md-25" (
				"height" 25%,
			),
			"-md-50" (
				"height" 50%,
			),
			"-md-75" (
				"height" 75%,
			),
			"-md-100" (
				"height" 100%,
			),
		));
		
	}
	
}

/**
 * Paddings
 */

.p {
	
	@include create-options((
		"-0" (
			"padding" 0,
		),
		"-1" (
			"padding" 1rem,
		),
		"-2" (
			"padding" 2rem,
		),
		"-3" (
			"padding" 3rem,
		),
		"-4" (
			"padding" 4rem,
		),
		"-5" (
			"padding" 5rem,
		),
		"b-0" (
			"padding-bottom" 0,
		),
	));
	
	// 768px and up
	@include media-query(md) {
		
		@include create-options((
			"-md-0" (
				"padding" 0,
			),
			"-md-1" (
				"padding" 1rem,
			),
			"-md-2" (
				"padding" 2rem,
			),
			"-md-3" (
				"padding" 3rem,
			),
			"-md-4" (
				"padding" 4rem,
			),
			"-md-5" (
				"padding" 5rem,
			),
			"t-md-3" (
				"padding-top" 3rem,
			),
		));
		
	}
	
}

/**
 * Margins
 */

.m {
	
	&b {
		
		@include create-options((
			"-0" (
				"margin-bottom" 0,
			),
			"-1" (
				"margin-bottom" 1rem,
			),
			"-2" (
				"margin-bottom" 2rem,
			),
			"-3" (
				"margin-bottom" 3rem,
			),
			"-4" (
				"margin-bottom" 4rem,
			),
			"-5" (
				"margin-bottom" 5rem,
			),
		));
		
		// 768px and up
		@include media-query(md) {
			
			@include create-options((
				"-md-0" (
					"margin-bottom" 0,
				),
				"-md-1" (
					"margin-bottom" 1rem,
				),
				"-md-2" (
					"margin-bottom" 2rem,
				),
				"-md-3" (
					"margin-bottom" 3rem,
				),
				"-md-4" (
					"margin-bottom" 4rem,
				),
				"-md-5" (
					"margin-bottom" 5rem,
				),
			));
			
		}
		
	}

}

/**
 * Text align
 */

.text {
	
	@include create-options((
		"-center" (
			"text-align" "center",
		),
		"-right" (
			"text-align" "right",
		),
	));
	
}

/**
 * Background image
 */

.bg-image {
	min-height: 30dvh;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	
	// 768px and up
	@include media-query(md) {
		min-height: 100%;
		border-radius: 2rem;
	}
	
}

/**
 * Texts
 */

h1 {
	@extend %title-font-size;
	margin-bottom: 1rem;
	font-weight: $font-weight-bold;
	line-height: 1.2;
}

p {
	@extend %text-font-size;
}

/**
 * iFrame
 */

iframe {
	width: 100%;
	height: 100%;
	min-height: 550px;
}

// Modules
@import "Components/loader";
@import "Components/header";
@import "Components/form-layout";
@import "Components/form";
@import "Components/footer";