$grey-25        : #FCFCFD;
$grey-50        : #F9FAFB;
$grey-100       : #F2F4F7;
$grey-200       : #EAECF0;
$grey-300       : #D0D5DD;
$grey-400       : #98A2B3;
$grey-500       : #667085;
$grey-600       : #475467;
$grey-700       : #344054;
$grey-800       : #202835;
$grey-900       : #101828;

$accent         : #2196F3;
$accent-hover   : #257dc6;