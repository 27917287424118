@charset "UTF-8";

// Globals
@import "src/Assets/Styles/utilities/includes";

// Extends
%border-radius {
	border-radius: 0.5rem;
}

/**
 * Form
 */

.form {
	
	&__message {
		@extend %border-radius;
		padding: 1.25rem 1.5rem 1rem;
		border-left: transparent solid 0.5rem;
		font-size: 1.4rem;
		line-height: 1.1;
		
		&--error {
			background-color: #ecc5c5;
			border-color: #851a1a;
			color: #851a1a;
		}
		
		&--success {
			background-color: #9be7b0;
			border-color: #16730d;
			color: #16730d;
		}
		
		&__list {
			margin-top: 0.5rem;
			list-style: disc inside;
		}
		
	}
	
	&__label {
		display: block;
		margin-bottom: 0.5rem;
		padding: 0 0.25rem;
		font: {
			size: 1.4rem;
			weight: $font-weight-bold;
		}
	}
	
	&__input {
		$self : &;
		@extend %border-radius;
		width: 100%;
		padding: 1rem 0.75rem;
		border: 1px solid $grey-300;
		outline: 0 solid transparent;
		transition: outline 0.25s ease, border-color 0.25s ease;
		font-size: 1.6rem;
		
		&:focus,
		&:focus-visible {
			border-color: $accent;
			outline: 0.3rem solid rgba($accent, 0.5);
		}
		
		&--disabled {
			opacity: .5;
		}
		
	}
	
	&__button {
		@extend %border-radius;
		width: 100%;
		padding: 1.25rem 0.75rem;
		background-color: $accent;
		border: none;
		outline: 0 solid transparent;
		transition: outline 0.25s ease, background-color 0.25s ease;
		font-size: 1.6rem;
		color: #fff;
		
		&:focus {
			background-color: $accent-hover;
			outline: 0.3rem solid rgba($accent, 0.5);
		}
		
		// 768px and up
		@include media-query(md) {
			cursor: pointer;
			
			&:hover {
				background-color: $accent-hover;
				outline: 0.3rem solid rgba($accent, 0.5);
			}
			
		}
		
		&-container {
			@extend %border-radius;
			position: relative;
			
			.loader.loader--absolute {
				
				.loader__opaque {
					@extend %border-radius;
					background-color: $accent !important;
				}
				
				.loader__spinner {
					border-top: 0.25rem solid lighten($accent, 10%);
					border-right: 0.25rem solid lighten($accent, 10%);
					border-bottom: 0.25rem solid lighten($accent, 10%);
					border-left: 0.25rem solid #fff;
				}
				
			}
		
		}
		
	}
	
}

/**
 * Toggle
 */

.toggle {
	$self : &;
	position: relative;
	display: inline-block;
	width: 3.5rem;
	height: 2rem;
	background-color: $grey-300;
	border-radius: 1rem;
	vertical-align: middle;
	outline: 0 solid transparent;
	transition: outline 0.25s ease, background-color 0.25s ease;
	
	// 768px and up
	@include media-query(md) {
		cursor: pointer;
		
		&:hover:not(#{$self}--disabled) {
			background-color: $grey-400;
			outline: 0.3rem solid rgba($grey-400, 0.5);
		}
		
	}
	
	&__input {
		display: none;
	}
	
	&__switch {
		position: absolute;
		top: 2px;
		left: 2px;
		width: calc(2rem - 4px);
		height: calc(2rem - 4px);
		background-color: #fff;
		border-radius: calc(1rem - 2px);
		transition: left 0.25s ease;
		
		// 768px and up
		@include media-query(md) {
			cursor: pointer;
		}
		
	}
	
	&--checked {
		background-color: $accent;
		
		#{$self}__switch {
			left: calc(100% - 1rem - 8px);
		}
		
		// 768px and up
		@include media-query(md) {
			
			&:not(#{$self}--disabled):hover {
				background-color: $accent-hover;
				outline: 0.3rem solid rgba($accent, 0.5);
			}
			
		}
		
	}
	
	&--disabled {
		opacity: .5;
	}
	
	&__label {
		display: inline-block;
		margin-left: 1rem;
		font-size: 1.4rem;
	}
	
}