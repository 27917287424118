@charset "UTF-8";

// Globals
@import "src/Assets/Styles/utilities/includes";

// Global max width
%max-width {
	max-width: $max-width;
	margin: {
		left: auto;
		right: auto;
	};
}

// Border radius
%border-radius {
	border-radius: 0.4rem !important;
}

// Small font sizes
%small-text-font-size {
	font-size: 1.2rem;
	
	// 992px and up
	@include media-query(md) {
		font-size: 1.3rem;
	}
	
}

// Medium font sizes
%medium-text-font-size {
	font-size: 1.4rem;
	
	// 992px and up
	@include media-query(md) {
		font-size: 1.5rem;
	}
	
}

// Global font sizes
%text-font-size {
	font-size: 1.6rem;
}

// Medium title font size
%medium-title-font-size {
	font: {
		weight: $font-weight-medium;
		size: 2rem;
	}
	
	// 992px and up
	@include media-query(md) {
		font-size: 1.4rem;
	}
	
	// 1200px and up
	@include media-query(xl) {
		font-size: 2.4rem;
	}
	
}

// Title font size
%title-font-size {
	font: {
		weight: $font-weight-medium;
		size: 2.2rem;
	}
	
	// 992px and up
	@include media-query(md) {
		font-size: 2.4rem;
	}
	
	// 1200px and up
	@include media-query(xl) {
		font-size: 2.8rem;
	}
	
}