@charset "UTF-8";

// Globals
@import "src/Assets/Styles/utilities/includes";

// Extends
@import "src/Assets/Styles/utilities/extends";

/**
 * Form layout
 */

.form-layout {
	@extend %max-width;
	
	.logotype {
		margin-bottom: 1rem;
		font-size: 1.2rem;
		color: $grey-400;
		text-transform: uppercase;
	}
	
}

/**
 * Inline form
 */

.form-inline {
	
	// 768px and up
	@include media-query(md) {
		min-width: 414px;
		max-width: 500px;
		border: 1px solid $grey-300;
		border-radius: 2rem;
	}

}