@charset "UTF-8";

// Globals
@import "src/Assets/Styles/utilities/includes";

// Extends
@import "src/Assets/Styles/utilities/extends";

/**
 * Footer
 */

.footer {
	text-align: center;
	font-size: 1.2rem;
	
	// 768px and up
	@include media-query(md) {
		text-align: left;
	}
	
	p {
		@extend %small-text-font-size;
		
		strong {
			font-weight: $font-weight-bold;
		}
		
	}
	
}